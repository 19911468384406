const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(244, 245, 246)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(155, 155, 155)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(209, 231, 236)',
  darkBlue: 'rgb(113, 134, 140)',
  green: 'rgb(116, 165, 74)',
  lightGreen: 'rgb(232, 236, 226)',
  darkGreen: 'rgb(136,144,129)',
  darkPurple: 'rgb(66,44,64)',
  lightPurple: 'rgb(180, 156, 185)',
  red: 'rgb(200, 49, 69)',
  lighterRed: ' rgb(222, 194, 194)',
  darkRed: ' rgb(95, 39, 39)',
  darkBrown: 'rgb(95,66,64)',
  darkerBrown: 'rgb(71, 64, 64)',
  lightBrown: 'rgb(229,222,217)',
  lightYellow: 'rgb(255, 246, 233)',

  brightOrange: '#e68532',
  brightBlue: '#3b91a4',
  brightYellow: '#ffd700',
  brightGreen: '#7b9f34',
  brightBrown: '#86482c',
};

const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};

const brandColors = {
  primary: '#4bb9e6',
  secondary: '#2e7795',
};

const textColors = {
  bodyText: '#000000',
  bodyTextWhite: '#ffffff',
  mutedText: '#7D7D7D',
  disabledText: '#B9B9B9',
  lightText: colors.white,
};

const textFontFamilies = {
  fontTypography: 'sweet-sans-pro, serif',
  fontBody1: 'sweet-sans-pro, serif',
  fontBody2: 'sweet-sans-pro, serif',
  fontHeadings: 'adobe-caslon-pro, sans-serif',
  fontBotton: 'adobe-caslon-pro, sans-serif',
};

const textFontSizesMobile = {
  fontSizeLeadText: '1.188rem',
  fontSizeTypography: '1rem',
  fontSizeButton: '0.875rem',
  fontSizeBody1: '1rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '2rem',
  fontSizeH2: '1.5rem',
  fontSizeH3: '1.188rem',
  fontSizeH4: '1.125rem',
};

const textFontSizesDesktop = {
  fontSizeLeadText: '1.25rem',
  fontSizeTypography: '1.063rem',
  fontSizeButton: '1rem',
  fontSizeBody1: '1.063rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '3.375rem',
  fontSizeH2: '2.25rem',
  fontSizeH3: '1.5rem',
  fontSizeH4: '1.125rem',
};

const textFontWeight = {
  typography: 'normal',
  button: '500',
  body1: 'normal',
  body2: 'normal',
  h1: '500',
  h2: '500',
  h3: '500',
  h4: '500',
};

const textLineHeightsMobile = {
  lineHeightLeadText: '1.47',
  lineHeightQuote: '1.47',
  lineHeightBody1: '1.47',
  lineHeightBody2: '1.5',
  lineHeightHeadings: '1.13',
};

const textLineHeightsDesktop = {
  lineHeightLeadText: '1.5',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.5',
  lineHeightBody2: '1.53',
  lineHeightHeadings: '1.17',
};

const typography = {
  h1: {
    marginBottom: '2.5rem',
  },
  h2: {
    marginBottom: '1.75rem',
    textTransform: 'none',
  },
  h3: {
    marginBottom: '1rem',
  },
  h4: {
    marginBottom: '1rem',
  },
  h5: {
    marginBottom: '1rem',
  },
  h6: {
    marginBottom: '1rem',
  },
};

const pageTemplates = {
  landingPage: {
    mobile: {
      paddingTop: 0, // => value x 8px
    },
    desktop: {
      paddingTop: 0, // => value x 8px
    },
  },
  otherPageTemplates: {
    mobile: {
      paddingTop: 40, // => value x 8px
    },
    desktop: {
      paddingTop: 40, // => value x 8px
    },
  },
};

const linkColors = {
  link: '#2e7795',
  linkHover: '#245d75',
  linkPressed: '#245d75',
};

const iconColors = {
  iconColor: '#000000',
};

const hr = {};

const backgroundColors = {
  paper: '#F8F6F3',
  grayLight: '#F0F0F0F0',
  grayDark: '#001928',
  blueLight: '#edf5f8',
  overlay: 'rgba(51,51,51,0.5)',
};

const formColors = {
  inputBorder: '#B4B4B4',
  inputBackground: '#F0F0F0',
  inputActiveBorder: '#418caa',
};

const primaryButtonColors = {
  primaryButtonBackground: '#2e7795',
  primaryButtonHoveredBackground: '#418caa',
  primaryButtonPressedBackground: '#418caa',
  primaryButtonDisabledBackground: '#808e94',
  primaryButtonContents: '#FFFFFF',
};

const secondaryButtonColors = {
  secondaryButtonBackground: '#f0f0f0',
  secondaryButtonHoveredBackground: '#edf5f8',
  secondaryButtonPressedBackground: '#edf5f8',
  secondaryButtonDisabledBackground: '#f0f0f0',
  secondaryButtonContents: '#2e7795',
  secondaryButtonHoveredContents: '#245d75',
  secondaryButtonPressedContents: '#245d75',
  secondaryButtonDisabledContents: '#808e94',
};

const outlinedButtonColors = {
  outlinedButton: '#2e7795',
  outlinedButtonHovered: '#ffffff',
  outlinedButtonPressed: '#245d75',
  outlinedButtonDisabled: '#808e94',
};

const notificationColors = {
  infoBackground: '#ecf1f8',
  successBackground: '#e6f9e8',
  warningBackground: '#fef3e8',
  errorBackground: '#fde9e9',

  infoContents: '#3d68a8',
  successContents: '#218e2c',
  warningContents: '#e8770e',
  errorContents: '#d71717',
};

const buttonSettings = {
  buttonPaddingDesktop: '12px 14px 12px 14px',
  buttonPaddingMobile: '14px 10px 8px 10px',
  borderRadius: '0',
  textTransform: 'inherit',
};

const header = {
  text: colors.white,
  background: backgroundColors.grayDark,
  minHeight: '280px',
  paddingYDesktop: '60px',
  navigation: {
    activeLink: brandColors.primary,
    activeLinkBorder: 'none',
    hoverText: linkColors.linkHover,
    subMenuBackground: colors.white,
    subMenuBulletPoints: colors.white,
  },
  border: {
    style: 'none',
    bottomWidth: 0,
  },
  icons: {
    color: colors.white,
    backgroundColor: colors.black,
  },
};

const footer = {
  border: {
    color: 'transparent',
    width: '3px',
  },
  divider: brandColors.primary,
  text: colors.white,
  background: backgroundColors.grayDark,
  logoBarBackground: backgroundColors.grayDark,
  padding: {
    bottom: '50px',
  },
};

const accordion = {
  backgroundColor: backgroundColors.grayLight,
  borderRadius: '12px',
  color: linkColors.link,
  flexDirection: 'row',
  hover: {
    backgroundColor: colors.white,
    color: linkColors.hover,
  },
  expandIcon: {
    backgroundColor: 'inherit',
    hover: {
      backgroundColor: 'inherit',
    },
  },
  padding: {
    left: '12px',
    right: '9px',
  },
};
const languageSelector = {
  color: colors.white,
  border: {
    color: formColors.inputBorder,
    width: '2px',
  },
  margin: '0',
};

const linkBlockModule = {
  center: {
    textAlign: 'center',
    '& > p, h1, h2, h3, h4, h5, a, div': {
      textAlign: 'center',
    },
  },
};

const table = {
  border: 'none',
  borderRadius: 0,
  head: {
    backgroundColor: backgroundColors.grayDark,
    color: colors.white,
  },
  row: {
    backgroundColor: colors.white,
    even: {
      backgroundColor: colors.white,
    },
  },
  cell: {
    border: `solid 1px ${formColors.inputBorder}`,
  },
};

const category = {
  outlined: {
    background: backgroundColors.grayLight,
    text: linkColors.link,
  },
  text: linkColors.link,
  hoverText: linkColors.linkHover,
};

const mobileNavigation = {
  text: colors.white,
  icon: colors.white,
  xIconBackground: backgroundColors.grayDark,
  logoBarBottomBorder: colors.darkBlue,
  navigationElementBorder: brandColors.primary,
  subNavigationTitleBackground: brandColors.secondary,
  subNavigationTitleColor: colors.white,
  drawer: {
    paper: {
      backgroundColors: backgroundColors.grayDark,
      widthDesktop: '480px',
    },
  },
};

const listElementCard = {
  content: {
    backgroundColor: 'white',
    padding: '24px',
    height: '100%',
  },
};

export const skagerrakPrimaryTheme = {
  colors,
  breakPoints,
  brandColors,
  textColors,
  textFontFamilies,
  textFontSizesMobile,
  textFontSizesDesktop,
  textFontWeight,
  textLineHeightsMobile,
  textLineHeightsDesktop,
  typography,
  pageTemplates,
  linkColors,
  iconColors,
  backgroundColors,
  formColors,
  primaryButtonColors,
  secondaryButtonColors,
  outlinedButtonColors,
  buttonSettings,
  notificationColors,
  header,
  footer,
  accordion,
  languageSelector,
  linkBlockModule,
  table,
  category,
  mobileNavigation,
  listElementCard,
  hr,
};
