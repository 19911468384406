import React from 'react';

import { Helmet } from 'react-helmet';

function DefaultFonts() {
  return (
    <Helmet>
      <link href="https://use.typekit.net/fkk1vht.css" rel="stylesheet" />
    </Helmet>
  );
}

export default DefaultFonts;
