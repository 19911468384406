const variables = {
  contentful: {
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    spaceId: process.env.CONTENTFUL_SPACE_ID,
    host: process.env.CONTENTFUL_HOST,
    environment: process.env.CONTENTFUL_ENVIRONMENT,
  },
  cloudinary: {
    cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
  },
  google: {
    tagManagerId: process.env.GATSBY_GOOGLE_TAG_MANAGER_ID,
  },
  site: {
    url: process.env.GATSBY_PHILIPSONSODERBERG_SITE_URL,
  },
};

export default variables;
