import React from 'react';

import { Helmet } from 'react-helmet';

function DefaultFonts() {
  return (
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/gvs3cvh.css" />
    </Helmet>
  );
}

export default DefaultFonts;
