import isEqual from 'lodash/isEqual';

import { siteIds } from '../constants';

import viinimaaSettings from './viinimaa/settings';
import folkofolkSettings from './folkofolk/settings';
import bibendumSettings from './bibendum/settings';
import blossaSettings from './blossa/settings';
import philipsonSoderbergSettings from './philipson-soderberg/settings';
import valhallaSettings from './valhalla/settings';
import gammeldanskSettings from './gammeldansk/settings';
import wennercoSettings from './wennerco/settings';
import koskenkorvaSettings from './koskenkorva/settings';
import opAndersonSettings from './op-anderson/settings';
import skagerrakSettings from './skagerrak/settings';
import wineworldFinlandSettings from './wineworld-finland/settings';
import linieAquavitSettings from './linieAquavit/settings';
import socialwinesSettings from './socialwines/settings';

import viinimaaVariables from './viinimaa/variables';
import folkofolkVariables from './folkofolk/variables';
import bibendumVariables from './bibendum/variables';
import blossaVariables from './blossa/variables';
import philipsonSoderbergVariables from './philipson-soderberg/variables';
import valhallaVariables from './valhalla/variables';
import gammeldanskVariables from './gammeldansk/variables';
import wennercoVariables from './wennerco/variables';
import koskenkorvaVariables from './koskenkorva/variables';
import opAndersonVariables from './op-anderson/variables';
import skagerrakVariables from './skagerrak/variables';
import wineworldFinlandVariables from './wineworld-finland/variables';
import linieAquavitVariables from './linieAquavit/variables';
import socialwinesVariables from './socialwines/variables';

export const siteId = process.env.GATSBY_SITE_ID;

export const settings = (() => {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return viinimaaSettings;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return folkofolkSettings;
  }
  if (isEqual(siteId, siteIds.Bibendum)) {
    return bibendumSettings;
  }
  if (isEqual(siteId, siteIds.Blossa)) {
    return blossaSettings;
  }
  if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
    return philipsonSoderbergSettings;
  }
  if (isEqual(siteId, siteIds.Valhalla)) {
    return valhallaSettings;
  }
  if (isEqual(siteId, siteIds.Gammeldansk)) {
    return gammeldanskSettings;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return wennercoSettings;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return koskenkorvaSettings;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return opAndersonSettings;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return skagerrakSettings;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return wineworldFinlandSettings;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return linieAquavitSettings;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return socialwinesSettings;
  }

  return {};
})();

export const variables = (() => {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return viinimaaVariables;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return folkofolkVariables;
  }
  if (isEqual(siteId, siteIds.Bibendum)) {
    return bibendumVariables;
  }
  if (isEqual(siteId, siteIds.Blossa)) {
    return blossaVariables;
  }
  if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
    return philipsonSoderbergVariables;
  }
  if (isEqual(siteId, siteIds.Valhalla)) {
    return valhallaVariables;
  }
  if (isEqual(siteId, siteIds.Gammeldansk)) {
    return gammeldanskVariables;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return wennercoVariables;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return koskenkorvaVariables;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return opAndersonVariables;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return skagerrakVariables;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return wineworldFinlandVariables;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return linieAquavitVariables;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return socialwinesVariables;
  }

  return {};
})();

export const { brandSite } = settings;
