import React from 'react';

import { get } from 'lodash';
import { Helmet } from 'react-helmet';

import { variables } from '../../../sites';

function MetaDomainVerificationTag() {
  const metaDomainVerificationCode = get(variables, 'meta.domainVerificationCode');
  if (typeof metaDomainVerificationCode === 'undefined') {
    return null;
  }

  return (
    <Helmet>
      <meta name="facebook-domain-verification" content={metaDomainVerificationCode} />
    </Helmet>
  );
}

export default MetaDomainVerificationTag;
