import React from 'react';

import { Helmet } from 'react-helmet';

function DefaultFonts() {
  return (
    <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet" />
      <link href="https://use.typekit.net/mqe7api.css" rel="stylesheet" />
    </Helmet>
  );
}

export default DefaultFonts;
