import { KlevuConfig } from '@klevu/core';

import get from 'lodash/get';

import { variables } from '../../../../sites';

KlevuConfig.init({
  url: get(variables, ['klevu', 'urlSearch']),
  apiKey: get(variables, ['klevu', 'apiKey']),
});

export default function KlevuInit() {
  return null;
}
