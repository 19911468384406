import React, { createContext, useReducer, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import { get, isNil, find, isEmpty, findIndex } from 'lodash';

const MemoryListContext = createContext({
  products: [],
  isAlreadyAdded: () => {},
  addToList: () => {},
  removeFromList: () => {},
});

function reducer(state, action) {
  switch (get(action, 'type')) {
    case 'addToList': {
      const product = get(action, 'payload');
      const nextProducts = [...state.products];
      const nextProductIndex = findIndex(nextProducts, { sku: get(product, 'sku') });
      if (nextProductIndex < 0) {
        nextProducts.push(product);
      }
      return { ...state, products: nextProducts };
    }
    case 'removeFromList': {
      const product = get(action, 'payload');
      const nextProducts = [...state.products];
      const nextProductIndex = findIndex(nextProducts, { sku: get(product, 'sku') });
      nextProducts.splice(nextProductIndex, 1);
      return { ...state, products: nextProducts };
    }
    default: {
      throw new Error(`Unhandled action type: ${get(action, 'type')}`);
    }
  }
}

export function MemoryListProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, { products: [] });

  const contextValue = useMemo(() => {
    const addToList = product => {
      dispatch({ type: 'addToList', payload: product });
    };

    const removeFromList = product => {
      dispatch({ type: 'removeFromList', payload: product });
    };

    const isAlreadyAdded = product => {
      return !isEmpty(find(get(state, 'products'), { sku: get(product, 'sku') }));
    };

    return {
      products: get(state, 'products'),
      isAlreadyAdded,
      addToList,
      removeFromList,
    };
  }, [state]);

  return <MemoryListContext.Provider value={contextValue}>{children}</MemoryListContext.Provider>;
}

MemoryListProvider.propTypes = {
  children: PropTypes.node,
};

MemoryListProvider.defaultProps = {
  children: null,
};

export function useMemoryList() {
  const context = useContext(MemoryListContext);
  if (isNil(context)) {
    throw new Error('useMemorylist must be used within MemoryListProvider');
  }
  return context;
}
