import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaNavigationElements from './viinimaa';
import FolkofolkNavigationElements from './folkofolk';

function NavigationElements(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaNavigationElements {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkNavigationElements {...props} />;
  }
  if (brandSite) {
    return <ViinimaaNavigationElements {...props} />;
  }

  return null;
}

export default NavigationElements;
