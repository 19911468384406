import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaFrosmoScript from './viinimaa';
import FolkofolkFrosmoScript from './folkofolk';

function FrosmoScript(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaFrosmoScript {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkFrosmoScript {...props} />;
  }

  return null;
}

export default FrosmoScript;
