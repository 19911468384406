exports.components = {
  "component---src-templates-advanced-page-template-viinimaa-index-js": () => import("./../../../src/templates/AdvancedPageTemplate/viinimaa/index.js" /* webpackChunkName: "component---src-templates-advanced-page-template-viinimaa-index-js" */),
  "component---src-templates-article-page-template-viinimaa-index-js": () => import("./../../../src/templates/ArticlePageTemplate/viinimaa/index.js" /* webpackChunkName: "component---src-templates-article-page-template-viinimaa-index-js" */),
  "component---src-templates-brand-page-template-viinimaa-index-js": () => import("./../../../src/templates/BrandPageTemplate/viinimaa/index.js" /* webpackChunkName: "component---src-templates-brand-page-template-viinimaa-index-js" */),
  "component---src-templates-category-page-template-viinimaa-index-js": () => import("./../../../src/templates/CategoryPageTemplate/viinimaa/index.js" /* webpackChunkName: "component---src-templates-category-page-template-viinimaa-index-js" */),
  "component---src-templates-landing-page-template-viinimaa-index-js": () => import("./../../../src/templates/LandingPageTemplate/viinimaa/index.js" /* webpackChunkName: "component---src-templates-landing-page-template-viinimaa-index-js" */),
  "component---src-templates-product-page-template-viinimaa-index-js": () => import("./../../../src/templates/ProductPageTemplate/viinimaa/index.js" /* webpackChunkName: "component---src-templates-product-page-template-viinimaa-index-js" */),
  "component---src-templates-recipe-page-template-viinimaa-index-js": () => import("./../../../src/templates/RecipePageTemplate/viinimaa/index.js" /* webpackChunkName: "component---src-templates-recipe-page-template-viinimaa-index-js" */)
}

