const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(244, 245, 246)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(180, 180, 180)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(209, 231, 236)',
  darkBlue: 'rgb(113, 134, 140)',
  green: 'rgb(116, 165, 74)',
  lightGreen: 'rgb(232, 236, 226)',
  darkGreen: 'rgb(136,144,129)',
  darkPurple: 'rgb(66,44,64)',
  lightPurple: 'rgb(180, 156, 185)',
  red: 'rgb(184, 73, 56)',
  lighterRed: ' rgb(222, 194, 194)',
  darkRed: ' rgb(95, 39, 39)',
  darkBrown: 'rgb(95,66,64)',
  darkerBrown: 'rgb(71, 64, 64)',
  lightBrown: 'rgb(229,222,217)',
  lightYellow: 'rgb(255, 246, 233)',

  brightOrange: '#e68532',
  brightBlue: '#3b91a4',
  brightYellow: '#ffd700',
  brightGreen: '#7b9f34',
  brightBrown: '#86482c',
};

const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};

const brandColors = {
  darkPurple: '#52222c',
  yellow: '#da974e',
  green: '#305440',
  red: '#B84938',
  brown: '#E4DBD0',
  lightGray: '#EBEDE6',
};

const textColors = {
  bodyText: '#000000',
  headingColor: brandColors.darkPurple,
  mutedText: '#7D7D7D',
  disabledText: '#B9B9B9',
  lightText: colors.white,
};
const textFontFamilies = {
  fontTypography: "'Jost', sans-serif",
  fontBody1: "'Jost', sans-serif",
  fontBody2: "'Jost', sans-serif",
  fontHeadings: "'Playfair Display', serif",
  fontBotton: "'Jost', sans-serif",
};
const textFontSizesMobile = {
  fontSizeLeadText: '1.125rem',
  fontSizeTypography: '0.938rem',
  fontSizeButton: '0.813rem',
  fontSizeBody1: '0.938rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '2.5rem',
  fontSizeH2: '1.625rem',
  fontSizeH3: '1.188rem',
  fontSizeH4: '1.125rem',
};
const textFontSizesDesktop = {
  fontSizeLeadText: '1.188rem',
  fontSizeTypography: '1rem',
  fontSizeButton: '0.938rem',
  fontSizeBody1: '1rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '4rem',
  fontSizeH2: '2.625rem',
  fontSizeH3: '1.5rem',
  fontSizeH4: '1.125rem',
};
const textFontWeight = {
  typography: 'normal',
  button: '600',
  body1: 'normal',
  body2: 'normal',
  h1: 'bold',
  h2: 'bold',
  h3: 'bold',
  h4: 'bold',
};
const textLineHeightsMobile = {
  lineHeightLeadText: '1.5',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.47',
  lineHeightBody2: '1.54',
  lineHeightHeadings: 'normal',
};

const textLineHeightsDesktop = {
  lineHeightLeadText: '1.58',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.5',
  lineHeightBody2: '1.54',
  lineHeightHeadings: 'normal',
};

const typography = {
  h1: {
    marginBottom: '2.5rem',
  },
  h2: {
    marginBottom: '1.75rem',
    textTransform: 'none',
  },
  h3: {
    marginBottom: '1rem',
  },
  h4: {
    marginBottom: '1rem',
  },
  h5: {
    marginBottom: '1rem',
  },
  h6: {
    marginBottom: '1rem',
  },
};

const pageTemplates = {
  landingPage: {
    mobile: {
      paddingTop: 0, // => value x 8px
    },
    desktop: {
      paddingTop: 0, // => value x 8px
    },
  },
  otherPageTemplates: {
    mobile: {
      paddingTop: 0, // => value x 8px
    },
    desktop: {
      paddingTop: 0, // => value x 8px
    },
  },
};

const linkColors = {
  link: '#DA974E',
  linkHover: '#AA6A24',
  linkPressed: '#653808',
};

const iconColors = {
  iconColor: '#333333',
};
const hr = {};

const backgroundColors = {
  paper: '#F8F6F3',
  grayLight: '#F0F0F0F0',
  grayMid: '#A9AEB2',
  grayDark: '#373737',
  overlay: 'rgba(51,51,51,0.5)',
};

const formColors = {
  inputBorder: '#B4B4B4',
  inputActiveBackground: '#F0F0F0',
  inputBackground: '#F0F0F0',
  inputActiveBorder: '#710EDB',
  inputBorderRadius: '0',
};

const primaryButtonColors = {
  primaryButtonBackground: '#305440',
  primaryButtonBackgroundGradient: 'none',
  primaryButtonHoveredBackground: '#1d402d',
  primaryButtonPressedBackground: '#072314',
  primaryButtonDisabledBackground: 'rgba(29, 64, 45, 0.4)',
  primaryButtonContents: '#FFFFFF',
};

const secondaryButtonColors = {
  secondaryButtonBackground: '#e4dbd0',
  secondaryButtonHoveredbackground: '#fff1e0',
  secondaryButtonPressedbackground: '#dccdba',
  secondaryButtonDisabledbackground: 'rgba(84, 59, 112, 0.4)',
  secondaryButtonContents: '#52222c',
};

const outlinedButtonColors = {
  outlinedButton: '#52222c',
  outlinedButtonHovered: '#fff',
  outlinedButtonPressed: '#1a0409',
  outlinedButtonDisabled: 'rgba(48, 15, 22, 0.4)',
  outlinedButtonContents: '#52222c',
};

const buttonSettings = {
  buttonPaddingDesktop: '16px 24px 16px 24px',
  buttonPaddingMobile: '12px 18px 12px 18px',
  borderRadius: 0,
  textTransform: 'inherit',
};

const notificationColors = {
  infoBackground: '#E2F1FF',
  successBackground: '#d8fde7',
  warningBackground: '#fff1ce',
  errorBackground: '#ffe3e8',

  infoContents: '#4f8bc2',
  successContents: '#399f62',
  warningContents: '#cb8600',
  errorContents: '#c81233',
};

const header = {
  text: colors.darkPurple,
  background: 'transparent',
  minHeight: '80px',
  paddingYDesktop: '60px',
  navigation: {
    activeLink: textColors.bodyText,
    activeLinkBorder: 'none',
    hoverText: colors.yellow,
    subMenuBackground: colors.white,
    subMenuBulletPoints: colors.white,
  },
  border: {
    bottomWidth: '3px',
    style: 'none',
  },
  icons: {
    color: colors.white,
    backgroundColor: colors.black,
  },
  backgroundGradient: {
    background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(47,47,47,0.50) 0%)',
  },
};

const footer = {
  border: {
    color: brandColors.lightGray,
    width: '3px',
  },
  divider: colors.darkerBrown,
  text: colors.white,
  background: brandColors.lightGray,
  logoBarBackground: brandColors.lightGray,
  padding: {
    bottom: '50px',
  },
};

const accordion = {
  backgroundColor: brandColors.brown,
  borderRadius: 0,
  color: brandColors.darkPurple,
  titleFontSize: '1.125rem',
  hover: {
    backgroundColor: brandColors.brown,
    color: linkColors.linkHover,
  },
  expandIcon: {
    backgroundColor: 'inherit',
    hover: {
      backgroundColor: 'inherit',
    },
  },
  padding: {
    left: '16px',
    right: '12px',
  },
};

const languageSelector = {
  color: 'white',
  border: {
    color: formColors.inputBorder,
    width: '2px',
  },
  margin: '0',
};

const table = {
  border: `solid 1px ${brandColors.darkPurple}`,
  borderRadius: 0,
  head: {
    backgroundColor: brandColors.darkPurple,
    color: colors.white,
  },
  row: {
    even: {
      backgroundColor: colors.white,
    },
  },
  cell: {
    border: `solid 1px ${brandColors.darkPurple}`,
  },
};

const category = {
  outlined: {
    background: brandColors.brown,
    text: brandColors.darkPurple,
  },
  text: brandColors.darkPurple,
  hoverText: brandColors.brown,
};

const mobileNavigation = {
  text: colors.darkBrown,
  icon: colors.darkBrown,
  xIconBackground: colors.lightBrown,
  logoBarBottomBorder: colors.darkBlue,
  navigationElementBorder: colors.lightGray,
  subNavigationTitleBackground: colors.lightGreen,
  subNavigationTitleColor: colors.darkBlue,
  drawer: {
    paper: {
      backgroundColors: backgroundColors.paper,
      widthDesktop: '350px',
    },
  },
};

export const philipsonSoderbergPrimaryTheme = {
  colors,
  breakPoints,
  brandColors,
  textColors,
  textFontFamilies,
  textFontSizesMobile,
  textFontSizesDesktop,
  textFontWeight,
  textLineHeightsMobile,
  textLineHeightsDesktop,
  typography,
  pageTemplates,
  linkColors,
  iconColors,
  backgroundColors,
  formColors,
  primaryButtonColors,
  secondaryButtonColors,
  outlinedButtonColors,
  buttonSettings,
  notificationColors,
  header,
  footer,
  accordion,
  languageSelector,
  table,
  category,
  mobileNavigation,
  hr,
};
