import { map, get, isEqual, isEmpty, isNil, reduce } from 'lodash';
import { v4 as uuid } from 'uuid';

import { isSSR } from './windowUtils';

import { createCustobarOrder, AnoraConversionEvent } from './custobarUtils';

const getProductBrand = productData => {
  let brand = get(productData, 'brands.0.title');
  if (isEmpty(brand)) {
    brand = get(productData, 'brands.0.menuTitle');
  }
  return brand;
};

const getProductCategory = productData => {
  let category = get(productData, 'categories.0.title');
  if (isEmpty(category)) {
    category = get(productData, 'categories.0.menuTitle');
  }
  return category;
};

const getProductsTotalPrice = productsData => {
  return reduce(
    productsData,
    (totalPrice, productData) => {
      return totalPrice + parseFloat(get(productData, 'attributes.monopoly_price.value'));
    },
    0,
  );
};

const getProductsCurrencyCode = productsData => {
  const locale = get(productsData, '0.node_locale');
  return isEqual(locale, 'fi-FI') ? 'EUR' : 'SEK';
};

export const viewPage = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({ event: 'pageView' });
};

export const pushFacebookCategory = category => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({ event: 'fb_category', campaign_category: category });
};

export const productClick = productData => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer) || isNil(productData)) {
    return;
  }

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_name: get(productData, 'name'),
          item_id: get(productData, 'sku'),
          price: get(productData, 'attributes.monopoly_price.value'),
          item_brand: getProductBrand(productData),
          item_category: getProductCategory(productData),
          item_list_name: 'Search Results',
          quantity: 1,
        },
      ],
    },
  });
};

export const viewProduct = productData => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer) || isNil(productData)) {
    return;
  }

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: get(productData, 'name'),
          item_id: get(productData, 'sku'),
          price: get(productData, 'attributes.monopoly_price.value'),
          item_brand: getProductBrand(productData),
          item_category: getProductCategory(productData),
          quantity: 1,
        },
      ],
    },
  });
};

export const viewProducts = productsData => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer) || isEmpty(productsData)) {
    return;
  }

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: map(productsData, (productData, index) => {
        return {
          item_name: get(productData, 'name'),
          item_id: get(productData, 'sku'),
          price: get(productData, 'attributes.monopoly_price.value'),
          item_brand: getProductBrand(productData),
          item_category: getProductCategory(productData),
          index,
          item_list_name: 'Search Results',
        };
      }),
    },
  });
};

export const viewCart = productsData => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer) || isEmpty(productsData)) {
    return;
  }

  const currencyCode = getProductsCurrencyCode(productsData);
  const totalPrice = getProductsTotalPrice(productsData);

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      currency: currencyCode,
      value: totalPrice,
      items: map(productsData, (productData, productIndex) => {
        return {
          item_name: get(productData, 'name'),
          item_id: get(productData, 'sku'),
          item_brand: getProductBrand(productData),
          item_category: getProductCategory(productData),
          item_list_name: 'Memory List',
          index: productIndex,
          price: get(productData, 'attributes.monopoly_price.value'),
          quantity: 1,
        };
      }),
    },
  });
};

export const addToCart = productData => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer) || isNil(productData)) {
    return;
  }

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_name: get(productData, 'name'),
          item_id: get(productData, 'sku'),
          price: get(productData, 'attributes.monopoly_price.value'),
          item_brand: getProductBrand(productData),
          item_category: getProductCategory(productData),
          quantity: 1,
        },
      ],
    },
  });
};

export const removeFromCart = productData => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer) || isNil(productData)) {
    return;
  }

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          item_name: get(productData, 'name'),
          item_id: get(productData, 'sku'),
          price: get(productData, 'attributes.monopoly_price.value'),
          item_brand: getProductBrand(productData),
          item_category: getProductCategory(productData),
          quantity: 1,
        },
      ],
    },
  });
};

export const purchaseProduct = (productData, customerToken) => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer) || isNil(productData)) {
    return;
  }

  const currencyCode = getProductsCurrencyCode([productData]);

  createCustobarOrder(productData, customerToken);
  AnoraConversionEvent(productData);

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: uuid(),
      affiliation: get(productData, 'attributes.channel.value'),
      value: get(productData, 'attributes.monopoly_price.value'),
      currency: currencyCode,
      items: [
        {
          item_name: get(productData, 'name'),
          item_id: get(productData, 'sku'),
          price: get(productData, 'attributes.monopoly_price.value'),
          item_brand: getProductBrand(productData),
          item_category: getProductCategory(productData),
          quantity: 1,
        },
      ],
    },
  });
};

export const purchaseProductFromCart = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({ event: 'alko_click_shopping_cart' });
};

export const subscribeNewsletter = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({ event: 'newsletterSubscribe' });
};

export const clickPrintButton = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({ event: 'printButton' });
};

export const filterProducts = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({ event: 'useFilter' });
};

export const searchUsage = (searchTerm, searchResultCount) => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);
  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({
    event: 'searchResult',
    searchTerm,
    searchResults: searchResultCount,
  });
};

export const viewVideo = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }
  dataLayer.push({ event: 'videoView' });
};

export const clickAmazonAffiliateLink = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }
  dataLayer.push({ event: 'amazonAffiliateLink' });
};

export const submitCampaignPageForm = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }
  dataLayer.push({ event: 'submitCampaignPageForm' });
};

export const viewCampaign = campaignData => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'view_promotion',
    ecommerce: {
      creative_name: '', // Can be empty
      creative_slot: '', // Can be empty
      promotion_id: get(campaignData, 'fullPath', ''), // Page url
      promotion_name: get(campaignData, 'title', ''), // Item name
      items: [
        {
          item_name: get(campaignData, 'featuredProducts.relatedProduct.name', ''),
          item_id: get(campaignData, 'featuredProducts.relatedProduct.sku', ''),
          item_brand: get(campaignData, 'featuredProducts.relatedProduct.brands.0.title', ''), // if used
          price: get(
            campaignData,
            'featuredProducts.relatedProduct.attributes.monopoly_reduced_price.value',
            get(campaignData, 'featuredProducts.relatedProduct.attributes.monopoly_price.value', ''),
          ),
          item_category: get(campaignData, 'featuredProducts.relatedProduct.categories.0.title', ''),
          quantity: 1,
        },
      ],
    },
  });
};

export const clickCampaignCard = campaignData => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'select_promotion',
    ecommerce: {
      creative_name: '', // Can be empty
      creative_slot: '', // Can be empty
      promotion_id: get(campaignData, 'fullPath', ''), // Page url
      promotion_name: get(campaignData, 'title', ''), // Item name
      items: [
        {
          item_name: get(campaignData, 'featuredProducts.relatedProduct.name', ''),
          item_id: get(campaignData, 'featuredProducts.relatedProduct.sku', ''),
          item_brand: get(campaignData, 'featuredProducts.relatedProduct.brands.0.title', ''), // if used
          price: get(
            campaignData,
            'featuredProducts.relatedProduct.attributes.monopoly_reduced_price.value',
            get(campaignData, 'featuredProducts.relatedProduct.attributes.monopoly_price.value', ''),
          ),
          item_category: get(campaignData, 'featuredProducts.relatedProduct.categories.0.title', ''),
          quantity: 1,
        },
      ],
    },
  });
};
