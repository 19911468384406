const variables = {
  contentful: {
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    spaceId: process.env.CONTENTFUL_SPACE_ID,
    host: process.env.CONTENTFUL_HOST,
    environment: process.env.CONTENTFUL_ENVIRONMENT,
  },
  magento: {
    host: process.env.GATSBY_MAGENTO_HOST,
    accessToken: process.env.GATSBY_MAGENTO_ACCESS_TOKEN,
  },
  cloudinary: {
    cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
  },
  reactAndShare: {
    articlePageKey: process.env.GATSBY_REACT_AND_SHARE_ARTICLE_PAGE_KEY,
    productKey: process.env.GATSBY_REACT_AND_SHARE_PRODUCT_KEY,
  },
  elastic: {
    host: process.env.GATSBY_ELASTIC_HOST,
  },
  google: {
    tagManagerId: process.env.GATSBY_GOOGLE_TAG_MANAGER_ID,
  },
  custobar: {
    host: process.env.CUSTOBAR_HOST,
    accessToken: process.env.CUSTOBAR_ACCESS_TOKEN,
  },
  site: {
    url: process.env.GATSBY_VIINIMAA_SITE_URL,
  },
  klevu: {
    urlSearch: process.env.GATSBY_KlEVU_URL_SEARCH,
    apiKey: process.env.GATSBY_KlEVU_API_KEY,
  },
};

export default variables;
