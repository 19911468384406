import React from 'react';

import isEqual from 'lodash/isEqual';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaFavicon from './viinimaa';
import FolkofolkFavicon from './folkofolk';
import BlossaFavicon from './blossa';
import BibendumFavicon from './bibendum';
import PhilipsonSoderbergFavicon from './philipsonSoderberg';
import ValhallaFavicon from './valhalla';
import GammeldanskFavicon from './gammeldansk';
import WennercoFavicon from './wennerco';
import KoskenkorvaFavicon from './koskenkorva';
import OpAndersonFavicon from './opAnderson';
import SkagerrakFavicon from './skagerrak';
import WineworldFinlandFavicon from './wineworld-finland';
import LinieAquavitFavicon from './linieAquavit';
import SocialwinesFavicon from './socialwines';

function Favicon(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
    return <PhilipsonSoderbergFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Blossa)) {
    return <BlossaFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Bibendum)) {
    return <BibendumFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Valhalla)) {
    return <ValhallaFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Gammeldansk)) {
    return <GammeldanskFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <WennercoFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <KoskenkorvaFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return <OpAndersonFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return <SkagerrakFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return <WineworldFinlandFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return <LinieAquavitFavicon {...props} />;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return <SocialwinesFavicon {...props} />;
  }

  return null;
}

export default Favicon;
