import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { CheckBoxOutlined } from '@material-ui/icons';
import { fofNavigationBreakpoint } from '../../constants';

import '../../fonts/folkofolk/Merriweather/Merriweather.css';
import '../../fonts/folkofolk/Montserrat/Montserrat.css';

export const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(244, 245, 246)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(155, 155, 155)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(209, 231, 236)',
  darkBlue: 'rgb(113, 134, 140)',
  green: 'rgb(116, 165, 74)',
  lightGreen: 'rgb(232, 236, 226)',
  darkGreen: 'rgb(136,144,129)',
  purple: 'rgb(184, 132, 203)',
  lightPurple: 'rgb(180, 156, 185)',
  darkPurple: 'rgb(66,44,64)',
  red: 'rgb(200, 49, 69)',
  lightRed: ' rgb(228, 140, 139)',
  lighterRed: ' rgb(222, 194, 194)',
  lighterRed2: 'rgb(249, 234, 236)',
  darkRed: ' rgb(95, 39, 39)',
  darkBrown: 'rgb(95,66,64)',
  darkerBrown: 'rgb(71, 64, 64)',
  lightBrown: 'rgb(229,222,217)',
  brightOrange: '#e68532',
  brightBlue: '#3b91a4',
  brightYellow: '#ffd700',
  lightYellow2: '#fff6e9',
  lightYellow: 'rgb(255, 246, 233)',

  brightGreen: '#7b9f34',
  brightBrown: '#86482c',
};

const theme = createTheme({});
const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};

const primaryTheme = createTheme({
  breakpoints: {
    values: breakPoints,
  },
  mixins: {
    toolbar: {
      minHeight: '70px',
    },
  },
  typography: {
    fontFamily: 'Merriweather, sans-serif',
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',
    h1: {
      fontFamily: '"Montserrat", serif',
      fontSize: '1.75rem',
      fontWeight: '400',
      lineHeight: '1.19',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '2.625rem',
      },
    },
    h2: {
      fontFamily: '"Montserrat", serif',
      fontSize: '1.375rem',
      fontWeight: '400',
      lineHeight: 'normal',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1.875rem',
      },
    },
    h3: {
      fontFamily: '"Montserrat", serif',
      fontSize: '1.125rem',
      fontWeight: '400',
      lineHeight: 'normal',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontFamily: '"Montserrat", serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: 'normal',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1.35rem',
      },
    },
    h5: {
      fontFamily: '"Montserrat", serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: 'normal',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1.25rem',
      },
    },
    h6: {
      fontFamily: '"Merriweather", sans-serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: 'normal',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1.25rem',
      },
    },
    caption: {
      fontSize: 'inherit',
      fontStyle: 'italic',
      lineHeight: '1.5',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: '700',
    },
    body1: {
      fontFamily: 'Merriweather, sans-serif',
      fontSize: '0.875rem',
      lineHeight: '1.8',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1rem',
      },
    },
    body2: {
      fontFamily: 'Merriweather, sans-serif',
      fontSize: '0.75rem',
      lineHeight: '1.6',

      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '0.813rem',
      },
    },
    navigationLink: {
      fontSize: '1rem',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      color: colors.darkBrown,
    },
    descriptionRow: {
      fontSize: '.875rem',
    },
  },
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
      gray: colors.gray,
      lightGray: colors.lightGray,
      lighterGray: colors.lighterGray,
      mediumGray: colors.mediumGray,
      darkGray: colors.darkGray,
      blue: colors.blue,
      lightBlue: colors.lightBlue,
      red: colors.red,
      darkRed: colors.darkRed,
      green: colors.green,
      lightGreen: colors.lightGreen,
      darkBrown: colors.darkBrown,
      darkerBrown: colors.darkerBrown,
      darkPurple: colors.darkPurple,
      lighterRed: colors.lighterRed,
      lightBrown: colors.lightBrown,
    },
    primary: {
      main: colors.black,
      contrastText: colors.white50,
    },
    secondary: {
      main: colors.darkerBrown,
      light: colors.lightYellow,
    },
    text: {
      primary: colors.darkerBrown,
      secondary: colors.white,
      light: colors.white,
    },
    background: {
      default: colors.white,
      breadcrumbs: colors.lightGray,
      linkBlock: colors.lightGray,
      defaultColumn: 'transparent',
      defaultRow: 'transparent',
    },
    error: {
      main: colors.red,
      light: colors.lighterRed2,
    },
    success: {
      main: colors.green,
      light: colors.lightGreen,
    },
    warning: {
      main: colors.brightOrange,
      light: colors.lightYellow,
    },
    info: {
      main: colors.blue,
      light: colors.lightBlue,
    },
    headingFontFamily: { fontFamily: '"Montserrat", serif' },
    divider: colors.lightGray,
    category: {
      outlined: {
        background: colors.lightBrown,
        text: colors.darkBrown,
      },
      text: colors.darkBrown,
      hoverText: colors.darkBlue,
    },

    footer: {
      border: {
        color: colors.darkBlue,
        width: '3px',
      },
      divider: colors.darkerBrown,
      text: colors.white,
      background: colors.darkerBrown,
      logoBarBackground: colors.lightGray,
      padding: {
        bottom: '50px',
      },
      columns: {
        list: {
          '& > li::Marker': {
            color: colors.white,
          },
        },
        texts: { color: colors.white },
      },
    },
    header: {
      text: colors.darkerBrown,
      background: colors.white,
      border: {
        style: 'solid',
      },
      navigation: {
        activeLink: colors.darkGreen,
        activeLinkBorder: colors.darkBlue,
        hoverText: colors.darkGreen,
        subMenuBackground: colors.lightGreen,
        subMenuBulletPoints: colors.darkBlue,
      },
      icons: {
        color: colors.white,
        backgroundColor: colors.darkerBrown,
      },
    },
    search: {
      quickSearch: {
        inputContainer: {
          backgroundColor: colors.lightGreen,
        },
        hideButton: {
          color: colors.black,
        },
      },
      filters: {
        title: {
          fontSize: '24px',
        },
        filter: {
          title: {
            fontSize: '1.1875rem',
          },
          label: {
            fontSize: '.875rem',
          },
        },
      },
    },
    mobileNavigation: {
      text: colors.darkerBrown,
      icon: colors.darkerBrown,
      xIconBackground: colors.lightBrown,
      logoBarBottomBorder: colors.darkBlue,
      navigationElementBorder: colors.lightGray,
      subNavigationTitleBackground: colors.lightGreen,
      subNavigationTitleColor: colors.darkBlue,
    },
    productPage: {
      informationBackground: colors.lightGreen,
      tasteProfiles: {
        orange: colors.brightOrange,
        purple: colors.brightYellow,
        green: colors.brightGreen,
        brown: colors.brightBrown,
        blue: colors.brightBlue,
      },
      mainInfo: {
        accordion: {
          color: colors.darkerBrown,
          iconBackground: colors.darkerBrown,
          description: {
            summaryBackground: colors.darkGreen,
            summaryText: colors.white,
          },
          information: {
            summaryBackground: colors.lightGreen,
            summaryText: colors.darkerBrown,
            detailesKey: colors.darkBrown,
          },
        },
      },
      review: {
        articlePadding: 0,
        background: colors.lightBlue,
        divider: colors.darkBlue,
      },
      labelBackground: colors.darkPurple,
    },
    quote: {
      text: colors.darkGreen,
      fontSize: '17px',
    },
    button: {
      fontWeight: 'bold',
      textTransform: 'inherit',
      [theme.breakpoints.up(breakPoints.md)]: {
        padding: '15px 10px 15px 10px',
      },
    },
    memoryListButton: {
      budge: { backgroundColor: colors.lightPurple },
    },
    link: {
      link: colors.darkBlue,
      active: colors.darkBlue,
      hover: colors.darkBlue,
    },
    pageTemplates: {
      landingPage: {
        paddingTop: theme.spacing(14),
        [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
          paddingTop: theme.spacing(10),
        },
      },
      campaignPage: {
        colors: {
          darkColor: '#333333',
          lightColor: colors.white,
        },
      },
      otherPageTemplates: {
        paddingTop: theme.spacing(14),
        [theme.breakpoints.up(breakPoints.sm)]: {
          paddingTop: theme.spacing(15),
        },
      },
      recipePage: {
        paddingTop: theme.spacing(14),
        [theme.breakpoints.up(breakPoints.sm)]: {
          paddingTop: theme.spacing(15),
        },
      },
    },
    custobarSubscriptionForm: {
      background: colors.darkPurple,
      hover: {
        background: colors.darkGreen,
      },
      disabled: {
        background: colors.lightGray,
      },
    },
    siteNotifications: {
      backgroundColor: colors.lightYellow2,
      color: '#ffa31c',
      padding: theme.spacing(2.5),
      margin: theme.spacing(4, 0, 4, 0),
      [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
        margin: theme.spacing(0, 0, 4, 0),
      },
    },
    monopolyPrice: {
      fontFamily: null,
      reducedPriceColor: null,
    },
  },
  languageSelector: {
    color: colors.black,
    border: {
      color: colors.black,
      width: '2px',
    },
    margin: '0',
  },
  table: {
    border: `solid 1px ${colors.black}`,
    borderRadius: 0,
    head: {
      backgroundColor: colors.black,
      color: colors.white,
    },
    row: {
      even: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  listElementCard: {
    content: {},
  },
  slider: {
    arrowBotton: {},
  },
  hr: {},
  imageSpotLink: {
    innerDot: {},
  },
  event: {
    root: {},
    day: {},
    month: {},
    name: {},
    shortDescription: {},
    icon: {},
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        color: colors.black,
        backgroundColor: colors.white,
      },
    },
    MuiToolbar: {
      regular: {},
      dense: {
        color: colors.white,
      },
    },
    MuiDrawer: {
      root: {
        width: '350px',
        flexShrink: 0,
      },
      paper: {
        width: '350px',
        color: colors.black,
        backgroundColor: colors.white,
      },
    },
    MuiLink: {
      root: {
        color: colors.darkBlue,
      },
    },
    MuiDialog: {
      paper: {
        margin: '5px',
      },
    },
    MuiButton: {
      root: {
        fontFamily: '"Montserrat", serif',
        textTransform: 'inherit',
        borderRadius: '0px',
        padding: '18px 20px',
        fontSize: '1rem',
        minWidth: '0',
      },
      sizeSmall: {
        padding: '9px 18px',
        borderRadius: '0px',
        fontSize: '0.8125rem',
      },
      containedPrimary: {
        boxShadow: 'none',
        border: '2px solid transparent',
        backgroundColor: colors.darkPurple,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.white,
          border: `2px solid ${colors.darkPurple}`,
          color: colors.darkPurple,
          '@media (hover: none)': {
            backgroundColor: colors.white,
          },
        },
        '&$disabled': {
          color: colors.mediumGray,
          opacity: 0.9,
        },
      },
      outlinedPrimary: {
        border: `2px solid ${colors.darkPurple}`,
        color: colors.darkPurple,
        '&:hover': {
          backgroundColor: colors.darkPurple,
          border: `2px solid transparent`,
          color: colors.white,
          '@media (hover: none)': {
            backgroundColor: colors.darkPurple,
          },
        },
        '&$disabled': {
          color: colors.black,
          opacity: 0.7,
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: colors.darkGray,
      },
      separator: {
        fontSize: '1.5rem',
        marginLeft: '16px',
        marginRight: '16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 8px',
        [theme.breakpoints.up('sm')]: {
          padding: '8px 24px',
        },
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid',
        borderColor: colors.gray,
        overflow: 'hidden',
        backgroundColor: colors.lightGreen,
        '&:hover:not($disabled)': {
          borderColor: colors.gray,
        },
        '& > svg': {
          color: colors.gray,
        },
        '&$focused': {
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.24)',
          borderColor: colors.lightGreen,
          backgroundColor: colors.white,
          '&:hover:not($disabled)': {
            borderColor: colors.lightGreen,
          },
        },
        '&$error:not($focused)': {
          borderColor: theme.palette.error.main,
          '&:hover:not($disabled)': {
            borderColor: theme.palette.error.main,
          },
        },
        '&$disabled': {
          backgroundColor: colors.lightGray,
        },
      },
      adornedStart: {
        paddingLeft: '0.5rem',
      },
      adornedEnd: {
        paddingRight: '0.5rem',
      },
      input: {
        fontSize: '1rem',
        padding: '0.625rem 1rem',
        '&:not(:first-child)': {
          paddingLeft: '0.5rem',
        },
        '&:not(:last-child)': {
          paddingRight: '0.5rem',
        },
        '&::placeholder': {
          fontStyle: 'italic',
        },
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        marginLeft: '0.75rem',
      },
      shrink: {
        marginTop: '-8px',
        transform: 'translate(0, 1.5px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.darkBrown,
        '&$error': {
          color: colors.darkRed,
        },
      },
      asterisk: {
        '&$error': {
          color: colors.darkRed,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.darkBrown,
        marginLeft: '0.75rem',
        '&$error': {
          color: theme.palette.error.main,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.darkerBrown,
        backgroundColro: colors.lightGray,
        '&$checked': {
          color: colors.darkerBrown,
          backgroundColro: colors.white,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: theme.spacing(2),
      },
    },
    MuiTypography: {
      root: {
        '& > li::Marker': {
          color: colors.darkBlue,
        },
      },
    },
    MuiList: {
      root: {
        listStyle: 'square',
        '& > li::Marker': {
          color: colors.darkBlue,
        },
      },
    },
    MuiCollapse: {
      hidden: {
        '@media print': {
          visibility: 'visible',
          height: 'auto',
        },
      },
    },
    MuiPaginationItem: {
      textPrimary: {
        '&.Mui-selected': {
          backgroundColor: colors.darkGreen,
          color: colors.white,
          fontWeight: 'bold',
        },
      },
    },
    MuiSlider: {
      thumb: {
        color: colors.darkGreen,
        '&:after': {
          display: 'none',
        },
      },
      track: {
        color: colors.darkGreen,
      },
    },
    PrivateValueLabel: {
      circle: {
        borderRadius: '50%',
      },
      label: {
        color: colors.white,
        fontWeight: 'bold',
      },
      offset: {
        top: 0,
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'primary',
    },
    MuiIconButton: {
      color: 'primary',
    },
    MuiButton: {
      color: 'primary',
      variant: 'contained',
    },
    MuiLink: {
      variant: 'body1',
    },
    MuiContainer: {
      disableGutters: false,
    },
    MuiBreadcrumbs: {
      separator: '›',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiCheckbox: {
      checkedIcon: <CheckBoxOutlined />,
    },
  },
});

export default primaryTheme;
