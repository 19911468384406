import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { variables } from '../../../../sites';

function BibendumFavicon() {
  const themeFaciconQuery = useStaticQuery(graphql`
    query BibendumThemeFaviconQuery {
      allContentfulTheme(
        filter: { channel: { elemMatch: { channel: { eq: "Bibendum EN" } } }, themeType: { eq: "Main theme" } }
      ) {
        nodes {
          title
          themeType
          themeStyles {
            favicon {
              cloudinaryImage {
                public_id
                url
                secure_url
              }
            }
          }
        }
      }
    }
  `);

  const cloudinaryImage = get(themeFaciconQuery, 'allContentfulTheme.nodes.0.themeStyles.favicon.cloudinaryImage.0');
  const publicId = get(cloudinaryImage, 'public_id');
  const url = `https://res.cloudinary.com/${get(
    variables,
    'cloudinary.cloudName',
  )}/image/upload/c_limit,q_auto:good,w_32/${publicId}`;

  return (
    <Helmet>
      <link rel="icon" type="image/x-icon" href={url} />
    </Helmet>
  );
}

export default BibendumFavicon;
