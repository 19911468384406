import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { CookiesProvider } from 'react-cookie';

import { DialogProvider } from '../../../context/DialogContext';
import { PopUpProvider } from '../../../context/PopUpContext';
import { MessageProvider } from '../../../context/MessageContext';
import { DrawerProvider } from '../../../context/DrawerContext';
import { ReactQueryProvider } from '../../../context/ReactQueryContext';
import { ContentProvider } from '../../../context/ContentContext';
import { MemoryListProvider } from '../../../context/MemoryListContext';
import { ActiveNavigationProvider } from '../../../context/ActiveNavigationContext';
import { ContentSearchContextProvider } from '../../../context/ContentSearchContext';
import { KlevuSearchProvider } from '../../../context/KlevuSearchContext';

import { TranslationProvider } from '../../../context/TranslationContext';

import GoogleTagManager from '../../GoogleTagManager';
import FrosmoScript from '../../FrosmoScript';
import DefaultFonts from '../../DefaultFonts.js';
import Favicon from '../../Favicon';
import MetaDomainVerificationTag from '../../MetaDomainVerificationTag';
import KlevuInit from '../../../search/KlevuInit';

function RootWrapper({ element }) {
  return (
    <>
      <Favicon />
      <GoogleTagManager />
      <FrosmoScript />
      <MetaDomainVerificationTag />
      <KlevuInit />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      </Helmet>
      <DefaultFonts />
      <ReactQueryProvider>
        <TranslationProvider>
          <KlevuSearchProvider>
            <DialogProvider>
              <CookiesProvider>
                <PopUpProvider>
                  <MemoryListProvider>
                    <MessageProvider>
                      <ActiveNavigationProvider>
                        <DrawerProvider>
                          <ContentSearchContextProvider>
                            <ContentProvider>{element}</ContentProvider>
                          </ContentSearchContextProvider>
                        </DrawerProvider>
                      </ActiveNavigationProvider>
                    </MessageProvider>
                  </MemoryListProvider>
                </PopUpProvider>
              </CookiesProvider>
            </DialogProvider>
          </KlevuSearchProvider>
        </TranslationProvider>
      </ReactQueryProvider>
    </>
  );
}

RootWrapper.propTypes = {
  element: PropTypes.node,
  props: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RootWrapper.defaultProps = {
  element: null,
  props: null,
};

export default RootWrapper;
